<template>
  <v-list
    dense
    v-if="groups.length === 1 || groups.length > 1 && listItems.length < 6"
    ref="list"
  >
    <template v-for="(group, groupIdx) in groups">
      <v-divider
        :key="groupIdx + 'div'"
        v-if="groupIdx > 0"
      />
      <v-subheader
        :key="groupIdx + 'hdr'"
        v-if="groups.length > 1"
      >{{$t($documentNameFromString(group.type))}}</v-subheader>
      <v-list-item
        v-for="(item, idx) in group.shown"
        :key="groupIdx + '.' + idx"
        @click="handleClick(item)"
      >
        <v-list-item-content>
          <document-ref-list-item
            :item="docSelector(item)"
            :chip="chip"
            @close="unlink(item)"
            v-on="$listeners"
            :readonly="readonly"
            :dense="dense"
          />
        </v-list-item-content>
        <v-list-item-action v-if="itemsForActions">
          <quick-actions
            :callback="actionCallback"
            :item="itemsForActions.get(`${item.id.type}:${item.id.id}`)"
          />
        </v-list-item-action>
      </v-list-item>
      <document-ref-list-more
        :key="groupIdx + 'more'"
        :group="group"
        @showMore="showMore"
      />
    </template>
  </v-list>
  <v-list
    dense
    v-else
    expand
  >
    <template v-for="(group, groupIdx) in groups">
      <v-list-group
        :key="group.type"
        v-if="(group.items.length > 1 && groups.length > 3) || group.items.length > 3"
        :value="true"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <span>{{$t($documentNameFromString(group.type))}}</span>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(item, idx) in group.shown"
          :key="groupIdx + '.' + idx"
          @click="handleClick(item)"
        >
          <v-list-item-content>
            <document-ref-list-item
              :item="docSelector(item)"
              :chip="chip"
              @close="unlink(item)"
              :readonly="readonly"
              :dense="dense"
            />
          </v-list-item-content>
          <v-list-item-action v-if="itemsForActions">
            <quick-actions
              :callback="actionCallback"
              :item="itemsForActions.get(`${item.id.type}:${item.id.id}`)"
            />
          </v-list-item-action>
        </v-list-item>
        <document-ref-list-more
          :key="groupIdx + 'more'"
          :group="group"
          @showMore="showMore"
        />
      </v-list-group>
      <template v-else>
        <v-divider
          :key="groupIdx + 'div'"
          v-if="groupIdx > 0"
        />
        <v-subheader :key="groupIdx + 'hdr'">{{$t($documentNameFromString(group.type))}}</v-subheader>
        <v-list-item
          v-for="(item, idx) in group.items"
          :key="groupIdx + '.' + idx"
          @click="handleClick(item)"
        >
          <v-list-item-content>
            <document-ref-list-item
              :item="docSelector(item)"
              :chip="chip"
              v-on="$listeners"
              :readonly="readonly"
              :dense="dense"
              @close="unlink(item)"
            />
          </v-list-item-content>
          <v-list-item-action v-if="itemsForActions">
            <quick-actions
              :callback="actionCallback"
              :item="itemsForActions.get(`${item.id.type}:${item.id.id}`)"
            />
          </v-list-item-action>
        </v-list-item>
      </template>
    </template>
  </v-list>
</template>
<script>
import Search from '@/pages/search/controllers'
import ItemList from '@/pages/search/controllers/item-list'
import navigation from '@/navigationController'
import vue from 'vue'

export default {
  components: {
    DocumentRefListItem: () => import('@/components/document-ref-list-item'),
    DocumentRefListMore: () => import('./document-ref-list-more'),
    QuickActions: () => import('@/pages/search/components/actions/quick-actions')
  },
  activated () {
    this.createSearch()
  },
  mounted () {
    this.createSearch()
  },
  deactivated () {
    this.search = undefined
  },
  computed: {
    groups () {
      return this
        .lodash
        .chain(this.listItems)
        .groupBy(i => this.docSelector(i).type)
        .map((items, type) => {
          const shown = items.length > 4 ? items.slice(0, 3) : items
          return vue.observable({ type, shown, items })
        })
        .value()
    },
    itemsForActions () {
      if (!this.linkTarget || !this.search) return
      this.search.setLinkTarget(this.linkTarget)
      const items = new ItemList(
        this.items.map(i => ({
          id: i.id.id,
          type: i.id.type,
          linked: { grantUsersOf: i.grantUsersOf, value: true }
        })),
        this.search.actionManager
      ).toArray()
      return new Map(this.items.map((item, index) => [`${item.id.type}:${item.id.id}`, items[index]]))
    },
    listItems: {
      get () {
        return this.lodash.cloneDeep(this.items ? this.items : [])
      },
      set (items) {
        this.$emit('update:items', items)
      }
    }
  },
  data () {
    return {
      navigating: false,
      search: null
    }
  },
  methods: {
    async handleClick (item) {
      if (this.navigating) { return }
      this.navigating = true
      try {
        if (this.navigate) {
          navigation.navigateTo(item.id.id, item.id.type)
        }
      } finally {
        this.navigating = false
      }
    },
    createSearch () {
      this.search = new Search(Search.actions.GRID)
    },
    showMore (group) {
      group.shown = group.items
    },
    unlink (item) {
      const index = this.listItems.findIndex(i => item.id === i.id)
      const clonedList = this.lodash.cloneDeep(this.listItems)
      this.$emit('item-closed', clonedList.splice(index, 1)[0])
      this.listItems = clonedList
    }
  },
  props: {
    actionCallback: Function,
    attachQuickActions: {
      type: [String, Boolean, Object, HTMLElement],
      default: undefined
    },
    chip: Boolean,
    items: Array,
    navigate: Boolean,
    docSelector: {
      type: Function,
      default: v => v
    },
    linkTarget: Object,
    readonly: Boolean,
    dense: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.v-list
  min-width 300px
  width 100%

.v-list-group .v-list-item
  overflow inherit
</style>
