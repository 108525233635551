export default {
  computed: {
    accountInfoTab () {
      return {
        documentType: 'account-infos',
        documentId: this.accountId,
        title: this.$t('t.Infos'),
        icon: '',
        isLoaded: false,
        isNew: false,
        isPristine: true,
        canSave: true,
        canUpdate: true,
        canClose: false,
        cancel: undefined,
        showComment: false
      }
    },
    accountStaticTabs () {
      const tabs = []

      tabs.push({
        documentType: 'documents',
        title: this.$t('t.Documents')
      })

      if (this.$store.getters.currentUserHasPermission('Accounts')) {
        tabs.push({
          documentType: 'account-summary',
          title: this.$t('t.Summary')
        })
      }

      tabs.push(this.accountInfoTab)

      if (this.$store.getters.currentUserHasPermission('Accounts')) {
        tabs.push({
          documentType: 'hierarchy',
          title: this.$t('t.Hierarchy')
        })
      }

      if (this.$store.getters.currentUserHasEveryPermissions(['Accounts', 'WorkItems'])) {
        tabs.push(
          {
            documentType: 'to-do',
            title: this.$t('t.ToDo'),
            icon: this.$icon('i.ToDo'),
            isLoaded: false,
            isNew: false,
            isPristine: true,
            canSave: true,
            canUpdate: true,
            canClose: false,
            cancel: undefined,
            showComment: false
          })
      }

      return tabs
    }
  }
}
