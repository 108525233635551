import Vue from 'vue'
import Http from '@/plugins/http'
import router from '@/router'
import i18n from '@/plugins/i18n'
import store from '@/store/store'
import accountStaticTabs from '@/pages/account/account-static-tabs'
import Debug from '@/debugger'
const debug = Debug('cot:recents:controller')

export default new Vue({
  Http,
  router,
  store,
  i18n,
  watch: {
    scope: {
      handler (n, o) {
        if (n && typeof n !== 'undefined' && n?.documentId !== o?.documentId) {
          debug('Current recent changed by watcher')
          this.setCurrent(n)
        }
      },
      deep: true
    },
    // This watcher handle URL access to documents like favorites and copy/pasted links
    async '$route' (route) {
      if (this.navigationInProgress) { return }
      const t = JSON.parse(route.query?.t ?? '{}')
      if (Object.keys(t).length) {
        await this.$waitFor(() => this.current)
        // Tabs must be processed one by one and not all replaced at once, this is to prevent loss of cancel and save methods on tabs
        t.tabs.forEach(t => {
          const tabIndex = this.current.tabs.findIndex(c => c.documentType === t.documentType)
          // If a tab of the same type exist
          if (tabIndex !== -1) {
            // And if it's a different documentId
            if (t.documentId !== this.current.tabs[tabIndex].documentId) {
              // Let recentsController handle document replacing
              this.addTab(t)
            }
          } else {
            this.current.tabs.push(t)
          }
        })
      }
    },
    async 'current.activeTab' (n, o) {
      if (this.navigationInProgress || typeof o === 'undefined') { return }
      debug('Active tab index is %i', n)
      const t = { i: n, tabs: this.current.tabs }
      this.updatePreviousTabs(n)
      this.$router.replace({
        path: this.$route.path,
        query: Object.assign({}, this.$route.query, { t: JSON.stringify(t) })
      }).catch(e => {
        if (e.name !== 'NavigationDuplicated') {
          throw e
        }
      })
    }
  },
  mixins: [accountStaticTabs],
  data () {
    return {
      current: null,
      loaded: false,
      recents: [],
      scope: null,
      displayPopUp: {},
      hold: {},
      navigationInProgress: false,
      http: this.$http().debounced(2000)
    }
  },
  computed: {
    recentsMaxLimit () {
      return 20
    },
    recentSettingsKey () {
      return 'recentsV6'
    }
  },
  created () {
    this.loadRecents()
  },
  methods: {
    updatePreviousTabs (index) {
      const previousIndex = this.current.previousTab.findIndex(t => t === index)
      if (previousIndex > -1) {
        this.current.previousTab.splice(previousIndex, 1)
      }
      if (index < this.accountStaticTabs.length) {
        this.current.previousTab = [index]
      } else {
        this.current.previousTab.push(index)
      }
      debug('Previous tab list updated %j', this.current.previousTab)
    },
    moveItemToTop (list, from) {
      list.unshift(list.splice(from, 1)[0])
    },
    setCurrent (scope) {
      if (!this.loaded) { return }
      debug('Changing scope to %j', scope)

      this.current = this.addUpdateRecent(scope)

      if (this.current.targetDocumentId) {
        const t = this.current.tabs.findIndex(t => t.documentId === this.current.targetDocumentId)
        if (t > -1) {
          this.current.activeTab = (this.current.accountStaticTabs.length || 0) + t
          this.current.targetDocumentId = null
        }
      }

      this.saveRecents()
    },
    addUpdateRecent (scope) {
      const recentIndex = this.recents.findIndex(r => r.documentId === scope.documentId)
      const recent = this.recents[recentIndex] || {
        documentId: scope.documentId,
        documentType: scope.documentType
      }

      if (recentIndex > -1) {
        debug('Get recent from history')
        this.moveItemToTop(this.recents, recentIndex)
      } else {
        debug('Create new recent')
        if (this.recents.length === this.recentsMaxLimit) {
          this.recents.pop()
        }

        this.$set(recent, 'previousTab', scope.previousTab || [0])
        this.$set(recent, 'activeTab', scope.activeTab || 0)
        this.$set(recent, 'tabs', scope.tabs || [])
        this.$set(recent, 'canCreateDocument', scope.canCreateDocument || true)

        this.recents.splice(0, 0, recent)
      }

      return recent
    },
    getRecentFromTab (tab) {
      return this.recents.find(r => r.documentId === tab.documentId || r.tabs.find(t => t.documentId === tab.documentId))
    },
    holdTabs (oldTab, newTab) {
      this.hold = Object.assign({}, this.hold, { oldTab, newTab })
    },
    addTab (tab, scope, isReplace) {
      const r = this.addUpdateRecent(scope ?? this.scope)
      // check to keep only 1 tab/document type.
      const oldTab = r.tabs.find(t => t.documentType === tab.documentType)
      if (oldTab) {
        const targetScope = scope ?? this.scope
        if (oldTab.documentId === tab.documentId) {
          this.navigationInProgress = true
          return this.goToRecent(targetScope, tab, isReplace).then(() => { this.navigationInProgress = false })
        }
        if (!oldTab.isPristine) {
          this.promptClosingTab(oldTab, tab, targetScope.documentId)
        } else {
          this.proceedPendingTabChanges(targetScope, oldTab, tab)
        }
      } else {
        this.proceedAddTab(tab, scope, isReplace)
      }
    },
    promptClosingTab (oldTab, tab, documentId = this.scope.documentId) {
      if (oldTab) {
        this.holdTabs(oldTab, tab)
      }
      this.showPopUp(documentId)
    },
    showPopUp (documentId) {
      this.displayPopUp = Object.assign(this.displayPopUp, { [documentId]: true })
    },
    proceedPendingTabChanges (scope, oldTab, tab) {
      if (oldTab && tab) {
        this.holdTabs(oldTab, tab)
      }
      this.cancel(this.hold.oldTab)
      if (!this.hold.oldTab.isPristine) {
        this.$store.dispatch('showWarningSnackbar', this.$t('t.Snackbar.ChangeRevertedConfirmation'))
      }
      if (this.hold.newTab) {
        this.navigationInProgress = true
        this.proceedAddTab(this.hold.newTab, scope).finally(() => { this.navigationInProgress = false })
      }
      this.closePopUp(scope.documentId)
    },
    closePopUp (documentId) {
      this.displayPopUp = Object.assign(this.displayPopUp, { [documentId]: false })
      this.clearHeldTabs()
    },
    clearHeldTabs () {
      Object.assign(this.$data, { hold: {} })
    },
    proceedAddTab (tab, scope, isReplace) {
      const recent = this.getRecentFromTab(scope || tab) ?? this.recents[0]
      if (!recent.tabs.includes(tab)) {
        recent.tabs.push(tab)
      }

      if (!recent.previousTab) {
        recent.previousTab = [0]
      } else if (recent.activeTab < this.accountStaticTabs.length) {
        recent.previousTab = [recent.activeTab]
      } else if (recent.previousTab[recent.previousTab.length - 1] !== recent.activeTab) {
        recent.previousTab.push(recent.activeTab)
      }

      this.saveRecents()

      return this.goToRecent({ documentType: recent.documentType, documentId: recent.documentId }, { documentId: tab.documentId }, isReplace)
    },
    async goToStaticTab (toScope, tabName) {
      this.navigationInProgress = true
      await this.navigateTo(toScope, null)
      const indexTab = this.accountStaticTabs.findIndex(tab => tab.documentType === tabName)
      await this.$waitFor(() => this.current, 2000)
      if (this.current) {
        this.current.activeTab = indexTab >= 0 ? indexTab : 0
      }
      this.navigationInProgress = false
    },
    async goToRecent (toScope, tab, isReplace) {
      await this.navigateTo(toScope, tab, isReplace)
      if (tab) {
        const tabIndex = this.current.tabs.findIndex(t => t.documentId === tab.documentId)
        if (this.current.activeTab === this.accountStaticTabs.length + tabIndex) {
          this.current.activeTab = 0
        }
        this.$nextTick(() => (this.current.activeTab = (toScope.documentType === 'accounts' ? this.accountStaticTabs.length : 0) + tabIndex))
      } else {
        this.current.activeTab = this.current.activeTab ?? 0
      }
    },
    async navigateTo (toScope, tab, isReplace) {
      debug('Navigate to scope %j tab %j ', toScope, tab)
      let path = `/${toScope.documentType}/${toScope.documentId}`
      if (toScope.params) {
        path += `?${Object.entries(toScope.params).map(param => param.join('=')).join('&')}`
      }

      if (this.$router.history.current.path !== path) {
        if (isReplace === true) {
          await this.$router.replace({ path }).catch(_ => { })
        } else {
          await this.$router.push({ path }).catch(_ => { })
        }
      }

      this.current = this.getRecentFromTab(tab || toScope)
    },
    close (tab) {
      if (!tab.canClose) { return }
      if (tab.isPristine) {
        this.cancel(tab)
      } else {
        this.promptClosingTab(tab)
      }
    },
    cancel (tab) {
      if (typeof tab.cancel === 'function') {
        tab.cancel()
      }
      this.removeTab(tab)
    },
    async save (tab) {
      await tab.save()
      this.removeTab(tab)
    },
    removeTab (tab) {
      // todo : marco : refacto to have parent info in tab...?
      const r = this.getRecentFromTab(tab)
      if (!r) { return }
      debug('Removing tab %j', tab)

      const index = r.tabs.findIndex(t => t.documentId === tab.documentId)
      if (index > -1) {
        let mostRecentTabIndex
        do {
          mostRecentTabIndex = r.previousTab[r.previousTab.length - 1]
          if (mostRecentTabIndex >= this.accountStaticTabs.length) {
            r.previousTab = r.previousTab.filter(tabIndex => tabIndex !== mostRecentTabIndex)
          }
        } while (mostRecentTabIndex === index + this.accountStaticTabs.length || mostRecentTabIndex >= this.accountStaticTabs.length + r.tabs.length)
        r.tabs.splice(index, 1)
        r.previousTab = r.previousTab.map(t => t > index + this.accountStaticTabs.length ? t - 1 : t)
        r.activeTab = mostRecentTabIndex - (mostRecentTabIndex > index + this.accountStaticTabs.length ? 1 : 0)
      }

      this.saveRecents()
    },
    clearRecents (firstRecentIsCurrent) {
      this.scope = null
      this.recents.splice(firstRecentIsCurrent ? 1 : 0, this.recents.length)
      this.saveRecents()
      debug('Recents cleared')
    },
    async loadRecents () {
      if (!this.$store.getters.currentUser.id) { return }
      const r = await this.$http().get('userSettings',
        {
          params: {
            key: this.recentSettingsKey,
            userId: this.$store.getters.currentUser.id
          }
        })

      this.recents = (r.data[0]?.value ?? []).filter(r => this.checkIfValidUUID(r.documentId))
      this.current = this.recents?.[0] ?? null
      if (this.current) {
        let t = new URLSearchParams(document.location.hash.split('?')[1]).get('t')
        t = t ? JSON.parse(t) : t
        const currentFromURL = t ? { activeTab: t.i, tabs: t.tabs } : {}
        this.current = Object.assign(this.current, currentFromURL)
      } else if (this.$route.name === 'account') {
        this.current = this.addUpdateRecent({ documentId: this.$route.params.id, documentType: 'accounts' })
      }

      debug('Recents loaded')
      this.loaded = true
    },
    checkIfValidUUID (str) {
      // Regular expression to check if string is a valid UUID
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

      return regexExp.test(str)
    },
    async saveRecents () {
      if (!this.loaded) { return }
      await this.http.post('userSettings',
        {
          key: this.recentSettingsKey,
          userId: this.$store.getters.currentUser.id,
          value: this.recents
        }
      )
        .then(() => debug('Recents saved'))
        .catch(e => {
          if (e !== 'Debounced') {
            throw e
          }
        })
    }
  }
})
