let debug
let registry
let references

if (process.env.NODE_ENV !== 'production') {
  debug = require('debug')
  const memoryDebugger = debug('cot:debugger:memory')
  references = new Set()
  registry = new FinalizationRegistry(value => {
    memoryDebugger(`Memory cleaned up for "${value}"`)
    references.delete(value)
  })
  window.cotReferencedObjects = references
}

export default function (name) {
  return debug?.(name) ?? function () { }
}

export function debugMemoryCleanUp (target, value) {
  references?.add(value)
  return registry?.register(target, value)
}
