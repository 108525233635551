import { render, staticRenderFns } from "./document-ref-list.vue?vue&type=template&id=50a61226&scoped=true&"
import script from "./document-ref-list.vue?vue&type=script&lang=js&"
export * from "./document-ref-list.vue?vue&type=script&lang=js&"
import style0 from "./document-ref-list.vue?vue&type=style&index=0&id=50a61226&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50a61226",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VDivider,VList,VListGroup,VListItem,VListItemAction,VListItemContent,VSubheader})
