import i18n from './plugins/i18n'
import Vue from 'vue'
import { v4 as uuid } from 'uuid'

const defaultTab = {
  documentId: null,
  documentType: null,
  typeName: null,
  icon: null,
  isLoaded: false,
  isNew: false,
  isPristine: true,
  canSave: false,
  canUpdate: true,
  canClose: true,
  cancel: undefined,
  showComment: false
}

export default new Vue({
  i18n,
  methods: {
    create (...options) {
      const object = Object.assign({}, defaultTab, ...options)
      if (!object.documentId) {
        object.documentId = uuid()
        object.isNew = true
      }

      return Vue.observable(object)
    },
    allocationAnnouncementsTab () {
      return {
        title: this.$t('t.AllocationAnnouncement'),
        documentType: 'allocation-announcements',
        typeName: this.$t('t.AllocationAnnouncement'),
        icon: this.$icon('i.AllocationAnnouncements')
      }
    },
    disputesTab () {
      return {
        title: this.$t('t.Dispute'),
        documentType: 'disputes',
        typeName: this.$t('t.Dispute'),
        icon: this.$icon('i.Disputes')
      }
    },
    calendarBasedRemindersTab () {
      return {
        title: this.$t('t.Reminder'),
        documentType: 'calendar-based-reminders',
        typeName: this.$t('t.Reminder'),
        icon: this.$icon('i.CalendarBasedReminders')
      }
    },
    dunningRemindersTab () {
      return {
        title: this.$t('t.Dunning'),
        documentType: 'dunning-reminders',
        typeName: this.$t('t.Dunning'),
        icon: this.$icon('i.DunningReminders')
      }
    },
    groupamaRequestsTab () {
      return {
        title: this.$t('t.CreditInsuranceGroupama'),
        documentType: 'groupama-requests',
        typeName: this.$t('t.CreditInsuranceGroupama'),
        icon: this.$icon('i.Groupama')
      }
    },
    invoicesTab () {
      return {
        title: this.$t('t.Invoice'),
        documentType: 'invoices',
        typeName: this.$t('t.Invoice'),
        icon: this.$icon('i.Invoices')
      }
    },
    promisesTab () {
      return {
        title: this.$t('t.Promise'),
        documentType: 'promises',
        typeName: this.$t('t.Promise'),
        icon: this.$icon('i.Promises')
      }
    },
    collaborationTasksTab () {
      return {
        title: this.$t('t.CollaborationTask'),
        documentType: 'collaboration-tasks',
        typeName: this.$t('t.CollaborationTask'),
        icon: this.$icon('i.CollaborationTasks')
      }
    },
    workItemTemplatesTab () {
      return {
        title: this.$t('t.FollowUp'),
        documentType: 'work-item-templates',
        typeName: this.$t('t.FollowUp'),
        icon: this.$icon('i.FollowUps')
      }
    },
    labelsTab () {
      return {
        title: this.$t('t.Label'),
        documentType: 'labels',
        typeName: this.$t('t.Label'),
        icon: this.$icon('i.Labels')
      }
    },
    labelGroupsTab () {
      return {
        title: this.$t('t.LabelGroup'),
        documentType: 'label-groups',
        typeName: this.$t('t.LabelGroup'),
        icon: this.$icon('i.LabelGroups')
      }
    },
    newMessageTab () {
      return {
        title: this.$t('t.Message'),
        documentType: 'new-message',
        typeName: this.$t('t.Message'),
        icon: this.$icon('i.NewEmail'),
        readonly: false
      }
    },
    messagesInboxTab (readonly) {
      return {
        title: this.$t('t.Message'),
        documentType: 'messages-inbox',
        typeName: this.$t('t.Message'),
        icon: this.$icon('i.MessagesInbox'),
        readonly: readonly ?? false
      }
    },
    messagesOutboxTab (readonly) {
      return {
        title: this.$t('t.Message'),
        documentType: 'messages-outbox',
        typeName: this.$t('t.Message'),
        icon: this.$icon('i.MessagesOutbox'),
        readonly: readonly ?? false
      }
    },
    // PortalPaymentTab () {
    //   return {
    //     title: this.$t('t.PortalPayment'),
    //     documentType: 'portal-payments',
    //     typeName: this.$t('t.PortalPayment'),
    //     icon: this.$icon('i.PortalPayment')
    //   }
    // },
    phoneTab () {
      return {
        title: this.$t('t.PhoneCall'),
        documentType: 'generic-task',
        typeName: this.$t('t.PhoneCall'),
        icon: this.$icon('i.Phone')
      }
    },
    accountContactsTab () {
      return {
        title: this.$t('t.Contact'),
        documentType: 'account-contacts',
        typeName: this.$t('t.Contact'),
        icon: this.$icon('i.AccountContacts')
      }
    },
    usersTab () {
      return {
        title: this.$t('t.User'),
        documentType: 'users',
        typeName: this.$t('t.User'),
        icon: this.$icon('i.User')
      }
    },
    genericTaskTab () {
      return {
        title: 'test',
        documentType: 'generic-task',
        icon: this.$icon('i.GenericTask'),
        typeName: 'test'
      }
    },
    topicsTab () {
      return {
        title: this.$t('t.Topic'),
        documentType: 'topics',
        typeName: this.$t('t.Topic'),
        icon: this.$icon('i.Topics')
      }
    },
    workQueuesTab () {
      return {
        title: this.$t('t.WorkQueue'),
        documentType: 'work-queues',
        typeName: this.$t('t.WorkQueue'),
        icon: this.$icon('i.WorkQueues')
      }
    },
    cameleonColumnItem (item) {
      const r = {
        // title: this.$t(item.title),
        documentType: 'columns',
        typeName: this.$t(item.typeName),
        icon: this.$icon(item.icon)
      }

      if (item.id) {
        r.documentId = item.id
      }
      return r
    },
    escalationProtocolTab () {
      return {
        title: this.$t('t.EscalationProtocol'),
        documentType: 'escalation-protocols',
        typeName: this.$t('t.EscalationProtocol'),
        icon: this.$icon('i.EscalationProtocols')
      }
    },
    agingScenarioTab () {
      return {
        title: this.$t('t.AgingScenario'),
        documentType: 'aging-scenarios',
        typeName: this.$t('t.AgingScenario'),
        icon: this.$icon('i.AgingScenarios')
      }
    },
    processTab () {
      return {
        title: this.$t('t.Process'),
        documentType: 'processes',
        typeName: this.$t('t.Process'),
        icon: this.$icon('i.Processes')
      }
    },
    businessDivisionTab () {
      return {
        title: this.$t('t.BusinessDivision'),
        documentType: 'business-divisions',
        typeName: this.$t('t.BusinessDivision'),
        icon: this.$icon('i.BusinessDivisions')
      }
    },
    providerTab () {
      return {
        title: this.$t('t.Provider'),
        documentType: 'providers',
        typeName: this.$t('t.Provider'),
        icon: this.$icon('i.Providers')
      }
    },
    getTabFromDocumentType (documentType) {
      let methodName = documentType.split('-').map(c => c.charAt(0).toUpperCase() + c.slice(1)).join('')
      methodName = methodName.charAt(0).toLowerCase() + methodName.slice(1) + 'Tab'

      const f = this[methodName]

      if (!f) {
        return false
      }
      return f()
    },
    buildPristineWatcher () {
      return {
        handler () {
          if (this.tab.isPristine && this.tab.isLoaded) {
            this.tab.isPristine = false
            window.dispatchEvent(new Event('resize'))
          }
        },
        deep: true
      }
    }
  }
})
